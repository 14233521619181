.btn-xsm,
.btn.btn-xsm,
.btn-group-xsm > .btn,
.ajax-upload-dragdrop .btn-group-xsm > .ajax-file-upload,
.swal2-modal .swal2-buttonswrapper .btn-group-xsm > .swal2-styled,
.wizard > .actions .btn-group-xsm > a {
    padding: 0.2rem 0.8rem;
    font-size: 0.65rem;
    line-height: 1.2;
    border-radius: 0.12rem;
}

/* Global classes */
@media (min-width: 1200px) {
    .container {
        max-width: 1240px !important;
    }
}

.card {
    box-shadow: 0 3px 6px 0 rgba(0,0,0,.16) !important;
}

.copy-to-clipboard {
    cursor: pointer;
}

.open-qrcode {
    cursor: pointer;
}

.table-no-top-border {
    border-top-style: hidden !important;
}

.table-no-border {
    border: none !important;
}

.block-explorer-section .table-latests td {
    border: none !important;
}

.block-explorer-section .table-latests th {
    border: none !important;
}

/* Wallet Show classes */
.button-wallet-action {
    user-select: none;
    background-color: #FFFFFF;
    border-radius: 5px;
    cursor: pointer;
    padding: 10px 15px 25px;
    position: relative;
    top: 0;
    transition: all 0.3s ease;
    border: 0;
    text-align: center;
}

.button-wallet-action:hover {
    background-color: #747FEB;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.12);
    top: -8px;
}

.button-wallet-action .button-image {
    font-size: 90px;
    margin-bottom: 5px;
}

.button-wallet-action p {
    line-height: 20px;
}

.button-wallet-action .small-note {
    color: #e96071;
    font-size: 11px;
    margin-top: 5px;
}

.button-wallet-action h3 {
    margin-bottom: 10px;
    font-size: 20px;
}

.button-wallet-action .button-software:hover {
    background-color: #e96071 !important;
    color: #FFFFFF !important;
}

/* Wallet Options Classes */
.button-wallet-options {
    display: grid;
    grid-row-gap: 9px;
}

.button-wallet-options .button-wallet-option {
    align-items: center;
    border: 1px solid #cecece;
    border-radius: 5px;
    color: #000;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    padding: 5px 20px;
    text-align: left;
    user-select: none;
}

.button-wallet-options .button-wallet-option span {
    font-weight: 500;
    line-height: 16px;
}

.button-wallet-options .button-wallet-option:hover {
    border: 1px solid #747FEB;
}

.selected {
    border: 1px solid #747FEB !important;
}

.button-wallet-options .button-wallet-option .selected {
    box-shadow: 0 8px 15px 0 rgba(59, 64, 69, 0.12);
}

.button-wallet-options .button-wallet-option .selected span {
    font-weight: 700;
}

.button-wallet-options .button-wallet-option .icon {
    margin-right: 8px;
    width: 50px;
}

.button-wallet-options .button-wallet-option .img-title-container {
    align-items: center;
    display: flex;
}

.button-wallet-options .button-wallet-option .title-link-container {
    display: flex;
    flex-direction: column;
}

.button-wallet-options .inactive {
    background-color: #FFFFFF !important;
}

.button-wallet-options .has-link {
    padding: 20px 30px !important;
}

/* Wallet HomePage classes */
.promo-cards {
    grid-column-gap: 30px;
    display: block;
}

.card-block {
    cursor: pointer;
    height: 100%;
    border-radius: 4px;
    color: white;
    margin-bottom: 0;
    padding: 30px 40px;
    position: relative;
    transition: all 0.3s ease;
}

.card-block:hover {
    box-shadow: 0 5px 24px rgba(0, 0, 0, 0.12);
    top: -10px;
}

.create-wallet {
    background-color: #747FEB !important;
}

.unlock-wallet {
    background-color: #06C0A5 !important;
}

.card-content h2 {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 5px;
    text-align: center;
}

.card-content p {
    line-height: 19px;
    margin-bottom: 20px;
    text-align: center;
}

.card-content .button {
    color: white;
    display: inline-block;
    font-size: 15px;
    font-weight: 500;
}
.card-content .button img {
    margin-left: 5px;
    vertical-align: middle;
    width: 20px;
}

/* Validator */
.validator-identity-title {
    padding-right: 30px;
    font-weight: bold;
}

/* Account */
.account-details-header {
    background-color: #747FEB !important;
    color: white;
    min-height: 90px;
}
